import { ICustomTermsAndConditionsFields, ICustomFootnoteFields } from '../../../../contentful-types'

export interface TermsAndConditionsProps {
  customTermsAndConditions?: ICustomTermsAndConditionsFields
}

export interface FootnoteProps {
  customFootnote?: ICustomFootnoteFields
}

export const termsAndConditionsDictionary = ({ customTermsAndConditions }: TermsAndConditionsProps = {}) => {
  return {
    en: {
      headingHide: customTermsAndConditions?.customHeadingHide || 'Hide terms and conditions',
      headingView: customTermsAndConditions?.customHeadingView || 'View terms and conditions',
      nonIndexedTitle:
        customTermsAndConditions?.customNonIndexedTitle || 'The following applies to all terms and conditions above',
      expandLabel: 'Expand',
      collapseLabel: 'Collapse',
    },
    fr: {
      headingHide: customTermsAndConditions?.customHeadingHide || 'Masquer les modalités et conditions',
      headingView: customTermsAndConditions?.customHeadingView || 'Voir les modalités et conditions',
      nonIndexedTitle:
        customTermsAndConditions?.customNonIndexedTitle ||
        "Ce qui suit s'applique aux modalités et conditions ci-dessus",
      expandLabel: 'Détailler',
      collapseLabel: 'Réduire',
    },
  }
}

export const footnoteDictionary = ({ customFootnote }: FootnoteProps = {}) => {
  return {
    en: {
      a11yLabel: 'Read legal footnote',
      close: 'close',
      heading: customFootnote?.customFootnoteHeading || 'Terms and conditions',
    },
    fr: {
      a11yLabel: 'Lire la note de bas de page légale',
      close: 'fermer',
      heading: customFootnote?.customFootnoteHeading || 'Modalités et conditions',
    },
  }
}
